import { createSlice } from '@reduxjs/toolkit'

export type propertyState = {
  isPropertyAuthenticate: any
}

const initialState: propertyState = {
  isPropertyAuthenticate: false
}

const propertySlice = createSlice({
  name: 'isPropertyAuthenticate',
  initialState,
  reducers: {
    setIsproprtyAuthenticate(state, action) {
      state.isPropertyAuthenticate = action.payload
    }
  }
})

export const { setIsproprtyAuthenticate } = propertySlice.actions
export default propertySlice.reducer
