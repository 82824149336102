/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 19/08/2024, 14:49:09
Modified: 16/09/2024, 16:09:00

Description:
  This file defines and exports the `AppConfig`, a configuration object containing various API prefixes, paths, and settings for the application. It centralizes critical configuration details, such as API endpoints, locale, and authentication paths, ensuring consistency across the application.
*/

export type AppConfig = {
  authApiPrefix: string
  propertyApiPrefix: string
  PaymentsApiPrefix: string
  reservationApiPrefix: string
  authenticatedEntryPath: string
  moyasarApiPrefix: string
  unAuthenticatedEntryPath: string
  channexApiPrefix: string
  tourPath: string
  locale: string
  enableMock: boolean
  propertyEntryPath: string
  channexIFramePrefix: string
}

const appConfig: AppConfig = {
  authApiPrefix: 'https://staging-auth-api.halmo.co/api/',
  propertyApiPrefix: 'https://staging-prop-api.halmo.co/api/',
  reservationApiPrefix: 'https://staging-reser-api.halmo.co/api/',
  PaymentsApiPrefix: 'https://staging-pay-api.halmo.co/api/',
  channexApiPrefix: 'https://staging.channex.io/api/v1/',
  channexIFramePrefix: 'https://staging.channex.io/auth',
  moyasarApiPrefix: 'https://api.moyasar.com/v1/',
  authenticatedEntryPath: '/home',
  propertyEntryPath: '/property',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: true
}

export default appConfig
