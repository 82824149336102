import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_SELECTED_PROPERTY_BASE_NAME } from './constants'

export type selectedPropertyState = {
  property: any
}

const selecteProperty = localStorage.getItem('selectedProperty')

let propertyData: any = null // Define the variable to hold parsed property

if (selecteProperty !== null) {
  try {
    propertyData = JSON.parse(selecteProperty) // Parse the property from localStorage
  } catch (error) {
    console.error('Error parsing user property:', error)
  }
} else {
  console.log('User property not found in localStorage')
}

const initialState: selectedPropertyState = {
  property: propertyData ? propertyData : null
}

const selectedPropertySlice = createSlice({
  name: `${SLICE_SELECTED_PROPERTY_BASE_NAME}/selectedProperty`,
  initialState,
  reducers: {
    setSelectedProperty(state, action) {
      state.property = action.payload
    }
  }
})

export const { setSelectedProperty } = selectedPropertySlice.actions
export default selectedPropertySlice.reducer
