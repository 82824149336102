import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import appConfig from '@/configs/app.config'
import { GET_NOTIFICATION } from './constants'
import dayjs from 'dayjs'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetCalendarNotificationState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetCalendarNotificationState: GetCalendarNotificationState =
  {
    currentRouteKey: '',
    apiData: [],
    status: 'idle',
    error: null
  }

// Define an async thunk for fetching API data
export const fetchGetCalendarNotificationReportData = createAsyncThunk(
  `${GET_NOTIFICATION}/fetchGetCalendarNotificationReportData`,
  async (paramData: { propId: any; date?: any }, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      ...(paramData?.date
        ? {
            url: `calendar/list-notification/?date=${dayjs(
              new Date(paramData?.date)
            ).format('YYYY-MM-DD')}&property=${paramData?.propId}`
          }
        : {
            url: `calendar/list-notification/?property=${paramData?.propId}`
          }),
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetCalendarNotificationSlice = createSlice({
  name: `${GET_NOTIFICATION}/getNotification`,
  initialState: initialGetCalendarNotificationState,
  reducers: {
    setCurrentGetCalendarNotificationRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetCalendarNotificationReportData.pending, state => {
        state.status = 'loading'
      })
      .addCase(
        fetchGetCalendarNotificationReportData.fulfilled,
        (state, action) => {
          state.status = 'succeeded'
          state.apiData = action.payload
        }
      )
      .addCase(
        fetchGetCalendarNotificationReportData.rejected,
        (state, action) => {
          state.status = 'failed'
          state.error = action.error.message || null
        }
      )
  }
})

export const { setCurrentGetCalendarNotificationRouteKey } =
  GetCalendarNotificationSlice.actions

export default GetCalendarNotificationSlice.reducer
